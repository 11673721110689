<template>
  <v-snackbar
    vertical
    v-model="snackbarVisible"
    timeout="-1"
    :color="snackbarColor"
    class="customSnackbar"
  >
    {{ textContent }}
    <v-progress-linear
      indeterminate
      v-if="letterIndex === 0"
      style="width: 100%; min-width: 248px"
      height="10"
      striped
      rounded
    />
    <v-dialog v-if="separateReveal" v-model="revealDialog">
      <v-card class="pa-0 pt-6 pb-2">
        <v-card-text v-html="cleanLetterPortion" />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="revealDialog = false" color="info">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="revealDialog = true"
        v-if="separateReveal"
      >
        Show
      </v-btn>
      <v-btn
        text
        v-bind="attrs"
        @click="$emit('read-letter')"
        v-if="readLetterButton"
      >
        Read Message
      </v-btn>
      <v-btn text v-bind="attrs" @click="clearSnackbar()"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<style>
.customSnackbar > div {
  max-width: 100vw !important;
  min-width: 280px !important;
}
</style>

<script>
/*

letterIndex: 0 = decoding (blue, info)
            -1 = does not belong (invalid QR)
            -2 = not allowed (no scans left for today)
            -3 = not allowed (scans haven't begun)
*/

export default {
  name: "ScanSnackbar",
  props: [
    "readLetter",
    "revealOnScan",
    "timeout",
    "value",
    "letterPortion",
    "letterIndex",
    "hasRevealed",
    "final",
  ],
  data() {
    return {
      snackbarVisible: false,
      snackbarTimeout: null,
      defaultTimeout: 10000,
      revealDialog: false,
      timeoutStart: 0,
      timeoutRemaining: 0,
      interruptedTimeout: false,
    };
  },
  computed: {
    readLetterButton() {
      return this.letterIndex != -4 && (this.readLetter || this.final);
    },
    snackbarColor() {
      switch (true) {
        case this.letterIndex === 0:
          return "info";
        case this.letterIndex < 0:
          return "error";
        case this.hasRevealed:
          return "warning";
        default:
          return "success";
      }
    },
    snackbarTime() {
      let passedTimeout = this.timeout;
      if (passedTimeout === undefined || passedTimeout === null) {
        return this.defaultTimeout;
      } else {
        passedTimeout = Number(passedTimeout);
      }
      if (Number.isNaN(passedTimeout)) {
        console.warn("Non-numeric value passed for Scan Snackbar Timeout");
        return this.defaultTimeout;
      } else if (parseInt(passedTimeout) != passedTimeout) {
        console.warn(
          `Non-intenger tiemout value (${passedTimeout}), using ${parseInt(
            passedTimeout
          )}.`
        );
        passedTimeout = parseInt(passedTimeout);
      }
      if (passedTimeout === 0) {
        console.warn(
          "Timeout of 0 for snackbar is deprecated, please use -1 instead"
        );
        passedTimeout = -1;
      } else if (passedTimeout < -1) {
        console.warn(
          `Unexpected timeout value (${passedTimeout}), assuming -1.`
        );
        passedTimeout = -1;
      }
      return passedTimeout;
    },
    cleanLetterPortion() {
      let letterPortion = this.letterPortion || "";
      // return letterPortion;
      let textNode = document.createTextNode(letterPortion);
      let p = document.createElement("p");
      p.appendChild(textNode);
      return p.innerHTML.replace(
        /\n/gi,
        // "↵"
        "<br />\n"
      );
    },
    separateReveal() {
      return (
        this.letterIndex != -4 &&
        (this.cleanLetterPortion.indexOf("<br />") > -1 ||
          this.snackbarText.length > 100)
      );
    },
    frontText() {
      let out = "";
      if (this.final) {
        out = `Congrats!  You just uncovered the whole message with part #${this.letterIndex}`;
      } else if (this.hasRevealed) {
        out = `You've already found part #${this.letterIndex}`;
      } else {
        out = `You just uncovered part #${this.letterIndex}`;
      }
      return out;
    },
    snackbarText() {
      let out = this.frontText;
      if (this.revealOnScan) {
        out += `: ${this.cleanLetterPortion}`;
      } else {
        out += ".";
      }
      return out;
    },
    textContent() {
      switch (this.letterIndex) {
        case 0:
          return "Decoding...";
        case -1:
          return "This QR Code doesn't appear to belong to Penny For Your Thoughts.";
        case -2:
          return "You have reached the maximum number of scans allowed today.";
        case -3:
          return "It isn't time to start scanning QR Codes yet.";
        case -4:
          return this.cleanLetterPortion;
      }
      if (this.separateReveal && this.revealOnScan) {
        return this.frontText + ". Press 'Show' to see what was uncovered.";
      } else if (this.separateReveal) {
        // there should be no case where this is a problem.
        return this.snackbarText;
      } else {
        return this.snackbarText;
      }
    },
  },
  methods: {
    getTime() {
      return performance ? performance.now?.() : new Date().getTime();
    },
    launchSnackbar(time = this.snackbarTime) {
      time = Math.ceil(time);
      let self = this;
      if (self.snackbarTimeout != null) {
        clearTimeout(self.snackbarTimeout);
      }
      self.snackbarVisible = true;
      self.$emit("input", self.snackbarVisible);
      if (time != -1) {
        self.timeoutStart = self.getTime();
        self.snackbarTimeout = setTimeout(self.clearSnackbar, time);
      }
    },
    clearSnackbar() {
      if (this.snackbarTimeout != null) {
        clearTimeout(this.snackbarTimeout);
        this.snackbarTimeout = null;
      }
      this.interruptedTimeout = false;
      this.snackbarVisible = false;
      this.$emit("input", this.snackbarVisible);
    },
  },
  watch: {
    value(newVal) {
      if (newVal && newVal !== this.snackbarVisible) {
        this.launchSnackbar();
      } else if (!newVal) {
        this.clearSnackbar();
      }
    },
    revealDialog(newVal) {
      if (newVal) {
        if (this.snackbarTimeout != null) {
          clearTimeout(this.snackbarTimeout);
          this.snackbarTimeout = null;
          this.timeoutRemaining =
            (this.interruptedTimeout
              ? this.timeoutRemaining
              : this.snackbarTime) -
            (this.getTime() - this.timeoutStart);
          this.interruptedTimeout = true;
        }
      } else {
        this.launchSnackbar(
          this.interruptedTimeout ? this.timeoutRemaining : this.snackbarTime
        );
      }
    },
  },
  mounted() {
    if (this.value) {
      this.launchSnackbar();
    }
  },
};
</script>
